<template>
  <div style="background: #f7f7f7;">
    <div class="train_page">
      <div class="top">
        <img :src="InfoList.backImage">
      </div>
    </div>
    <div class="tainText">
      <h6>{{InfoList.title}}</h6>
      <p>{{InfoList.descContent}}</p>
      <!--PDF在线预览-->
      <div class="pdf tab">
        <img
            src="../../assets/img/test/pdf.png"
            style="width: 50px; margin-bottom: 10px"
            alt="活动介绍在线浏览"/>
        <div>
          <a target="_blank" :href="InfoList.introduceURL">活动介绍在线浏览</a>
        </div>
      </div>
    </div>
    <div class="list">
      <div class="formList">
        <h6>参培人信息</h6>
        <div class="garden" v-if="$store.state.userInfo.schoolId && !$store.state.userInfo.teacherId">
          <div class="box">
            <label>园所名称:</label>
            <input type="text" disabled  v-model="formlist.schoolName">
          </div>
          <div class="box">
            <label>园长姓名:</label>
            <input type="text" :disabled="status" v-model="formlist.contacts">
          </div>
          <div class="box">
            <label>联系方式:</label>
            <input type="text" :disabled="status" v-model="formlist.contactsMobile">
          </div>
        </div>
        <div class="garden teacherId" v-else>
          <div class="box">
            <label>园所名称:</label>
            <input type="text" disabled  v-model="formlist.schoolName">
          </div>
          <div class="box">
            <label>教师姓名:</label>
            <input type="text" disabled v-model="formlist.contacts">
          </div>
          <div class="box">
            <label>联系方式:</label>
            <input type="text" disabled v-model="formlist.contactsMobile">
          </div>
        </div>
        <div class="hint">
          提示：请确认参培人是否为园长，若确认直接点击提交报名，若不是请修改信息
        </div>
        <label class="checkbox">
          <input type="checkbox" class="check" v-model="isCheck">
          <span></span>
          我已认真了解并阅读
          <a target="_blank" :href="InfoList.noticeURL">《报名须知》</a>
        </label>
        <button class="submitBtn" @click="noSubmit">提交报名</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "trainCategory",
  components: {},
  data() {
    return {
      lists:[],
      InfoList:[],
      //园长
      formlist:{},
      isCheck: false,
      status : false,
    };
  },
  created() {},
  mounted() {
    if(this.$store.state.userInfo.schoolId && !this.$store.state.userInfo.teacherId){
      console.log('院长')
      this.getUserInfo()
    }
    if(this.$store.state.userInfo.schoolId && this.$store.state.userInfo.teacherId){
      console.log('教师')
      this.getTeacheInfo()
    }
    this.getTrainInfo()
  },
  methods: {
    //园长信息 用户信息
    getUserInfo() {
      let that = this
      var data = {}
      var schoolId = that.$store.state.userInfo.schoolId
      this.api.school.getatteFindSchool(data, schoolId).then((res) => {
        console.log('用户信息',res)
        that.formlist ={
          schoolName: res.data.schoolName,
          contacts: res.data.contacts,
          contactsMobile: res.data.contactsMobile
        }
      })
    },
    //查看教师信息
    getTeacheInfo(){
      let that = this
      let data={
        id: this.$store.state.userInfo.teacherId
      }
      this.api.admin.findTeachersInfoById(data).then(res =>{
        console.log('教师信息',res)
        //拿到userInfo里的信息
        var schoolInfo = JSON.parse(localStorage.getItem('schoolInfo'))
        console.log('schoolInfo',schoolInfo.atteSchool.schoolName)
        that.formlist ={
          schoolName: schoolInfo.atteSchool.schoolName,
          contacts: res.data.atteTeacher.name,
          contactsMobile: res.data.atteTeacher.phone
        }
      })
    },
    //线上培训详情
    getTrainInfo(){
      let data={
         schoolId : this.$store.state.userInfo.schoolId
       }
       console.log('data',data);
       this.api.train.trainInfo(data).then((res)=>{
        console.log('res',res.data[0].backImage);
        this.InfoList = res.data[0];
      })
    },


    //提交
    noSubmit(){
      //验证是否勾选 问读报名须知
      if (!this.isCheck) {
        this.$notice({
          message: "请先阅读报名须知",
        }).isShow();
        return;
      }

      var data= {
         ...this.formlist
      }

      if(this.$store.state.userInfo.schoolId&& !this.$store.state.userInfo.teacherId){
         data.status = 0 //园长
         data.schoolId = this.$store.state.userInfo.schoolId
      }
      if(this.$store.state.userInfo.schoolId && this.$store.state.userInfo.teacherId){
        data.status = 1  //教师
        data.teacherId = this.$store.state.userInfo.teacherId
      }
      this.api.train.submitTrainInfo(data).then((res)=>{
        console.log('提交成功')
        if(res.flag){
          this.$notice({
            type: "success",
            message: res.message,
          }).isShow();
          this.status = true;
        }
      })

    }
  }
};
</script>

<style lang="scss" scoped>
.list .box input:disabled{
  background-color : #fafafa;
  color: #666;
  opacity:1;
  -webkit-text-fill-color:#666;
  -webkit-opacity:1;
}
.train_page {
    width: 100%;
    height: 575px;
    background: url("../../assets/img/train/Pic_Bg.png") no-repeat;
    background-size: cover;
    padding: 89px 0 95px 0;
    box-sizing: border-box;
    .top{
      width: 1030px;
      height: 391px;
      margin: 0 auto;
      img{
        //width:100%;
        //height: auto;
        display: inline-block;
        width: 10.3rem;
        height: 3.91rem;
      }
    }
}
.tainText{
  width: 1431px;
  margin: 40px auto;
  h6{
    color: #CB1B31;
    font-size: 30px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  p{
    font-size:24px;
    color: #2C3E50;
  }

}
.pdf {
  color: #cb1b31;
  text-decoration: underline;
  margin-top: 40px;
  display: inline-block;
}
.list{
  background: #fff;
  .formList{
    width: 1431px;
    margin: 0 auto;
    padding:  51px 0 70px 0;
  }
  h6{
    font-size: 30px;
    font-weight: bold;
    color: #CB1B31;
    margin-bottom: 25px;
  }
  .garden ,.teacher{
    display: flex;
  }
  .box{
    flex-grow: 3;
    label{
       font-size: 16px;
       font-weight: 400;
       color: #333333;
       padding-right:10px;
     }
    input{
      width: 308px;
      height: 40px;
      background: #FFFFFF;
      border: 1px solid #DDDDDD;
      border-radius: 4px;
      padding: 0 10px;
      box-sizing: border-box;
      font-size: 16px;
      color: #333333;
    }
   }
  .hint{
    font-size: 16px;
    font-weight: 400;
    color: #CB1B31;
    padding: 30px 0;
  }

}
.checkbox{
  display: inherit;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}
.checkbox input {
  opacity: 0;
  position: absolute;
}
.checkbox span {
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url('../../assets/img/train/Icon_MS_Us .png') no-repeat;
  background-size: cover;
}
.checkbox input:checked ~ span {
  background: url('../../assets/img/train/Icon_MS.png') no-repeat;
  background-size: cover;
}
.checkbox a{
  color: #CB1B31;
}
.submitBtn{
  display: block;
  width: 220px;
  height: 38px;
  line-height: 38px;
  border:none;
  background: linear-gradient(232deg, #B93037, #EF525B);
  box-shadow: 0 3px 6px 0 rgba(255, 67, 77, 0.33);
  border-radius: 19px;
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  margin: 30px auto 0 auto;
}

</style>